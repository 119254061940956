import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store, { persistor } from './store/index';
import reportWebVitals from './reportWebVitals';
// import SimpleReactLightbox from "simple-react-lightbox";
import ThemeContext from "./context/ThemeContext";
import { PersistGate } from 'redux-persist/integration/react';

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>

            {/* <SimpleReactLightbox> */}
            {/* <BrowserRouter basename='/react/demo'> */}
            <BrowserRouter >
                <ThemeContext>
                    <PersistGate loading={null} persistor={persistor}>
                        <App />
                    </PersistGate>
                </ThemeContext>
            </BrowserRouter>
            {/* </SimpleReactLightbox> */}
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);
// reportWebVitals();
