
import { useDispatch } from 'react-redux';
import { createAlert } from '../../store/slices/alertSlice';

const useAlert = () => {
    const dispatch = useDispatch();

    const pushAlert = (title: string, titleText: string, icon: "success" | "warning" | "danger", buttonText: string = "Xác nhận", buttonValue: string = "OK", timer: number = 0) => {
        dispatch(createAlert({
            title,
            titleText,
            icon,
            buttonText,
            buttonValue,
            timer
        }))
    }

    return pushAlert;
}

export default useAlert;