import { paserParams } from "../until";
import axiosClient, { ResponseSuccess, ResponseError } from "./axiosClient";
import { ResponseStatus } from "./matBangLoApi";

//CẤU HÌNH EMAIL CHƯA CÓ API VÀ CÁCH LÀM
//Cấu hình
export interface Configuration {
    id?: string;
    ma?: string;
    ten?: string;
    giaTri?: string;
    moTa?: string;
    taoBoi?: string;
    taoLuc?: string;
    capNhatBoi?: string;
    capNhatLuc?: string;
    isPrivate?: boolean
}
export interface ConfigurationList {
    data: Configuration[];
    totalRow?: number
}

export interface AddConfiguration {
    data: {
        idQuyen?: string;
    }

}
export interface UpdateConfiguration {
    data: Configuration
}

//danh sách
export const getListConfiguration =
    async (PageNumber: number, PageSize: number = 25, Ma: string = '', Ten?: string, GiaTri?: string, MoTa?: string, capNhatLucStart?: string, capNhatLucEnd?: string
    ) => {
        const path = "cau-hinh/items";
        const params = {
            PageNumber,
            PageSize,
            search: {
                Ma,
                Ten,
                GiaTri,
                MoTa,
                capNhatLuc: {
                    start: capNhatLucStart,
                    end: capNhatLucEnd
                }
            }
        }
        const result: ResponseSuccess<ConfigurationList> = await axiosClient.get(path, {
            params: paserParams(params, undefined)
        });
        return result;
    }

//thông tin 
export const getInfoConfiguration = async (id: string) => {
    const path = "cau-hinh/" + id;
    const result: ResponseSuccess<Configuration> = await axiosClient.get(path, {
    });
    return result;
}

//thêm 
export const createConfiguration =
    async (Ma?: string, Ten?: string, GiaTri?: string, MoTa?: string
    ) => {
        const path = "cau-hinh";
        const params = {
            Ma, Ten, GiaTri, MoTa
        }
        const result: ResponseSuccess<AddConfiguration> = await axiosClient.post(path, paserParams(params, ""));
        return result;
    }

//sửa
export const updateConfiguration =
    async (
        Id?: string, Ma?: string, Ten?: string, GiaTri?: string, MoTa?: string
    ) => {
        const path = "cau-hinh/";
        const params = {
            Id, Ma, Ten, GiaTri, MoTa
        }
        const result: ResponseSuccess<UpdateConfiguration> = await axiosClient.put(path, paserParams(params, ""));
        return result;
    }

//xóa
export const removeConfiguration = async (id: string) => {
    const path = "cau-hinh/" + id;
    const result: ResponseStatus = await axiosClient.delete(path, {
    });
    return result;
}

export const CauHinhIdentify = async (Ma: string) => {
    const path = "/cau-hinh/identify/" + Ma;
    const result: ResponseSuccess<Configuration> = await axiosClient.get(path, {
    });
    return result;
}


