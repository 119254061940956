
import Select from "react-select";
// import { colourOptions } from "./data";

const CustomClearText = () => <>Xóa</>;
const ClearIndicator = (props: any) => {
    const {
        // children = <CustomClearText />,
        children = <CustomClearText />,
        getStyles,
        innerProps: { ref, ...restInnerProps },
    } = props;
    return (
        <div
            {...restInnerProps}
            ref={ref}
            style={getStyles("clearIndicator", props)}
        >
            <div style={{ padding: "0px 5px" }}>{children}</div>
        </div>
    );
};

const ClearIndicatorStyles = (base: any, state: any) => ({
    ...base,
    cursor: "pointer",
    color: state.isFocused ? "blue" : "black",
});

interface MultiSelectFilterProps {
    isDisabled?: boolean;
    defaultValue?: any;
    value?: any;
    closeMenuOnSelect?: boolean;
    options: any;
    isMulti?: boolean;
    placeholder?: string;
    className?: string;
    isSearch?: boolean;
    isClearable?: boolean;
    HandleOption?: (value: any) => void;
    onOpen?: () => void;
}

const MultiSelectFilter = ({ isDisabled = false, value, defaultValue = {}, closeMenuOnSelect = false, options, isMulti = false, HandleOption, placeholder = "Chọn", isSearch = false, className = "", isClearable, onOpen }: MultiSelectFilterProps) => {
    const onChange = (Select: any) => {
        if (isMulti) {
            HandleOption?.(Select)
        } else {
            HandleOption?.(Select?.value)
        }
    }
    const onCheckOpen = () => {
        // console.log(options);

        if (options?.length == 0 || !options) {
            onOpen?.()
        }
    }
    return (
        <Select
            isDisabled={isDisabled}
            // menuIsOpen={true}
            className={`select-filter form-select h-auto py-0 ${className}`}
            placeholder={isSearch ? "Tất cả" : placeholder}
            isSearchable={true}
            menuPosition="fixed"
            closeMenuOnSelect={closeMenuOnSelect}
            isClearable={isClearable}
            components={{ ClearIndicator }}
            styles={{ clearIndicator: ClearIndicatorStyles }}
            isMulti={isMulti}
            noOptionsMessage={() => "Đã tải hết dữ liệu"}
            options={options}
            defaultValue={defaultValue}
            // defaultValue={[colourOptions[4], colourOptions[5]]}
            value={value}
            onChange={onChange}
            onMenuOpen={onCheckOpen}
        />
    );
};

export default MultiSelectFilter;
