import { Fragment, useContext, useState } from "react";
/// React router dom
import { Link } from "react-router-dom";
import marketInfo from "../../../api/marketInfo.json";
import { ThemeContext } from "../../../context/ThemeContext";
import logo from "../../../logo/logo.png";


const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  const openMenuToggle: any = useContext(
    ThemeContext
  );
  return (
    <div className="nav-header">
      <Link to="/trang-chu" className="brand-logo">
        <Fragment>
          <img src={logo} width="57" height="57" />
          <div className="brand-title mt-0">
            <h3 className="text-primary fw-bold mb-0">{marketInfo.name}</h3>
          </div>
        </Fragment>
      </Link>

      <div
        className="nav-control"
        onClick={() => {
          setToggle(!toggle);
          openMenuToggle();
        }}
      >
        <div className={`hamburger ${toggle ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
