import { paserParams } from "../until";
import axiosClient, { ResponseSuccess, ResponseError } from "./axiosClient";

export interface User {
    data: Array<{
        idNguoiDung?: string;
        maDangNhap?: string;
        email?: string;
        xacThucEmail?: string;
        soDienThoai?: string;
        anhDaiDien?: string;
        tenNguoiDung?: string;
        khoaDen?: string;
        lanTruyCapLoi?: string;
        idTrangThai?: string;
        idDonVi?: string;
        taoBoi?: string;
        taoLuc?: string;
        capNhatBoi?: string;
        capNhatLuc?: string;
        tenDonVi?: string;
        tenTrangThai?: string;
    }>,
    totalRow?: number

}
export interface UserInfo {
    idNguoiDung?: string;
    maDangNhap?: string;
    email?: string;
    xacThucEmail?: string;
    soDienThoai?: string;
    anhDaiDien?: string;
    tenNguoiDung?: string;
    khoaDen?: string;
    lanTruyCapLoi?: string;
    idTrangThai?: string;
    idDonVi?: string;
    taoBoi?: string;
    taoLuc?: string;
    capNhatBoi?: string;
    capNhatLuc?: string;
    tenDonVi?: string;
    tenTrangThai?: string;
    // idCho: Array<{
    //     idCho?: string,
    //     tenCho?: string
    // }>,
    nguoiDungCho: Array<{
        idCho?: string,
    }>
}


export const layThongTinCuaToi = async () => {
    const path = "nguoi-dung";
    const result: ResponseSuccess<UserInfo> = await axiosClient.get(path, {
    });
    return result;
}

export const getListUser = async (PageNumber: number, PageSize: number = 25, MaDangNhap: string = "", Email: string = "", SoDienThoai: string = "", TenNguoiDung: string = "", IdDonVi: Array<any> = [], IdTrangThai: Array<any> = [], Startday: string = "", endday: string = "") => {

    const path = "nguoi-dung/items";
    const params = {
        PageNumber,
        PageSize,
        search: {
            MaDangNhap,
            Email,
            SoDienThoai,
            TenNguoiDung,
            IdDonVi,
            IdTrangThai,
            capNhatLuc: {
                start: Startday,
                end: endday
            }
        }
    }
    const result: ResponseSuccess<User> = await axiosClient.get(path, { params: paserParams(params, undefined) });
    return result;
}
//list nhân viên
// //combo nhân viên quản lý
// export const getListNhanvien = async () => {
//     const path = "nguoi-dung/get-all";
//     const result: ResponseSuccess<Array<UserInfo>> = await axiosClient.get(path, {
//     });
//     return result;
// }

export const layThongTinNguoiDung = async (idNguoiDung: string) => {
    const path = "nguoi-dung/" + idNguoiDung;
    const result: ResponseSuccess<UserInfo> = await axiosClient.get(path, {});

    return result;
}

// export const getInfoUser = async (idNguoiDung: string) => {
//     const path = "nguoi-dung/" + idNguoiDung;
//     const result: ResponseSuccess<UserInfo> = await axiosClient.get(path, {
//     });
//     return result;
// }

export const addNewUser = async (MaDangNhap: string, MatKhau: string, Email: string, SoDienThoai: string, avt: string | BinaryData, TenNguoiDung: string, IdTrangThai: string | null, IdDonVi: string | null, idCho: Array<any> = [], isAdmin: boolean) => {
    const path = "nguoi-dung";
    var AnhDaiDien = avt != "" ? avt : null;
    const params = {
        MaDangNhap, MatKhau, Email, SoDienThoai, AnhDaiDien, TenNguoiDung, IdTrangThai, IdDonVi, IdCho: idCho, isAdmin
    }
    const result: ResponseSuccess<UserInfo> = await axiosClient.post(path, paserParams(params, ""));
    return result;
}

//chỉnh sửa người dùng
export const editNewUser = async (IdNguoiDung: string, MaDangNhap: string, Email: string, SoDienThoai: string, AnhDaiDien: string, TenNguoiDung: string, KhoaDen: Date | string | null, IdTrangThai: string | null, IdDonVi: string | null, MatKhau?: string, isAdmin: boolean = false) => {
    const path = "nguoi-dung";
    const params = {
        IdNguoiDung, MaDangNhap, MatKhau, Email, SoDienThoai, AnhDaiDien, TenNguoiDung, KhoaDen, IdTrangThai, IdDonVi, isAdmin
    }
    const result: ResponseSuccess<UserInfo> = await axiosClient.put(path, paserParams(params, ""));

    return result;
}
//chỉnh sửa một phần người dùng
export const editUserList = async (IdNguoiDung: string, MaDangNhap: string, Email: string, SoDienThoai: string, avt: string | BinaryData, TenNguoiDung: string, KhoaDen: Date | string | null, IdTrangThai: string | null, IdDonVi: string | null, MatKhau?: string, idCho: Array<any> = [], isAdmin: boolean = false) => {
    const path = "nguoi-dung";
    var AnhDaiDien = avt != "" ? avt : null;
    const params = {
        IdNguoiDung, MaDangNhap, MatKhau, Email, SoDienThoai, AnhDaiDien, TenNguoiDung, KhoaDen, IdTrangThai, IdDonVi, IdCho: idCho, isAdmin
    }
    const result: ResponseSuccess<UserInfo> = await axiosClient.patch(path, paserParams(params, ""));

    return result;
}

//xóa người dùng
export const DelUser = async (idNguoiDung: string) => {
    const path = "nguoi-dung/" + idNguoiDung;
    const result: ResponseSuccess<string> = await axiosClient.delete(path, {
    });
    return result;
}
