import axios from "axios";
import qs from 'qs';
import { API_URL } from "../env";
import tokenService from '../services/token.service';

export interface ResponseSuccess<T> {
    success?: boolean;
    data: T;
    message?: string;
}

export interface ResponseError {
    status: number,
    title: string,
    errors: {
        [key: string]: string[]
    }
}

const axiosClient = axios.create({
    baseURL: API_URL,
    headers: {
        'content-type': 'multipart/form-data',
        // 'content-type': 'application/json',
        // "Content-Type": "application/x-www-form-urlencoded"
        // 'Authorization': 'Bearer ' + validToken()
    },
    paramsSerializer: {
        serialize: params => {
            return qs.stringify(params, { arrayFormat: 'repeat', allowDots: true });
        }
    }
});

axiosClient.interceptors.request.use(async (config) => {
    //handle token here
    const token = tokenService.getToken();
    config.headers!.Authorization = 'Bearer ' + token;
    if (config.method !== "get") {
        var newData = new FormData();
        if (config.data) {
            for (var key in config.data) {
                //paser array format to repeat
                if (Array.isArray(config.data[key])) {
                    // console.log(config.data[key]);
                    for await (const item of config.data[key]) {
                        newData.append(key, item);
                    }
                }
                else {
                    newData.append(key, config.data[key]);
                }
            }
        }
        // console.log(newData);

        config.data = newData;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

axiosClient.interceptors.response.use((response) => {
    if (response && response.data) {
        return response.data;
    }

    return response;
}, (error) => {
    // console.error(error.response.data);
    if (error.response && error.response.data && error.response.data.error &&
        (error.response.data.session === false || error.response.data.session === "false")) {
        alert("Đã xảy ra sự cố, sẽ tự động đăng xuất.");
        tokenService.removeToken();
        window.location.href = "/";
    }
    else if (error.response && error.response.data && error.response.data.error && error.response.data.error.message) {
        // toastMessage(error.response.data.error.message, 1);
        return error.response?.data
    }
    else if (error.response && error.response.status === 500) {
        if (error.response.data?.message.search("DELETE") > -1) {
            var mess = "Dữ liệu cơ sở đang được sử dụng. Vui lòng thực hiện xoá dữ liệu đang dùng trước.";
            var data = {
                message: mess,
                success: false
            }
            return data
        } else {
            return error.response.data;
        }


    }
    else if (error.response && error.response.status === 401) {
        alert("Bạn đã hết phiên đăng nhập, vui lòng đăng nhập lại!");
        tokenService.removeToken();
        // window.location = "/";
        window.location.href = "/";
    } else {
        return error.response.data;
        // return Promise.reject(error);
    }

});

export default axiosClient;