export const API_URL = "https://api-quanlycho.lifo.vn/";
export const ERROR_MESSEGE_NULL = " không được trống!";
export const ERROR_MESSEGE_POSITIVE_NUMBERS = " không được âm!";

export const LENGTH_MAX = 225;
export const LENGTH_MIN = 4;
export const LIMIT_TIME = 20;

export default {
    process: {
        env: {
            API_URL,
            ERROR_MESSEGE_NULL,
            LENGTH_MAX,
            LENGTH_MIN,
            LIMIT_TIME
        }
    }
}