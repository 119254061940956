import React, { Fragment, useEffect, useMemo, useState } from "react";
import swal from "sweetalert";
import ColumnSelect from 'react-column-select'
import { Button, Modal } from "react-bootstrap";
import { Link, RouteComponentProps } from "react-router-dom";
import { gDetailNguoiDung } from "../../../../api/trangCaNhanApi";
import * as nguoiDungApi from "../../../../api/nguoiDungApi";
import profile from "../../../../images/profile/profile.png";
import avatarPlaceholder from "../../../../images/avatarPlaceholder.png";
import { formatPhoneNumber, formatShowPhoneNumber } from "../../../../until";
import { COLUMNS_PERMISSION } from "../../../pages/MenuPermission/components/Data/ColumnsPermission";
import MOCK_DATA from "../../../pages/MenuPermission/components/Data/FAKE_DATA.json";
import ModalAddPermission from "../../../pages/MenuPermission/components/ModalAddPermission";
import ModalEditPermission from "../../../pages/MenuPermission/components/ModalEditPermission";
import AccordionCard from "../../AccordionCard";
import TableData from "../../TableData";
import moment from "moment";
import ButtonLoading from './../../ButtonLoading/index';
import { useSelector } from "react-redux";
import { selectorUser } from "../../../../store/slices/userSlice";
import * as chucnangnguoidungApi from "../../../../api/chucnangnguoidungApi";
import { getFileUrl } from "../../../../api/defaultApi";

interface AppProfileProps
	extends RouteComponentProps<{
		idNguoiDung: string,
	}> { }
interface objectQuyen {
	idNguoiDung?: string,
	idQuyen?: string;
	tenQuyen?: string;
}

const AppProfile: React.FC<AppProfileProps> = ({ history, match }) => {
	let UserId: string | null;
	const UserRedux = useSelector(selectorUser);
	const { idNguoiDung, idDonVi, tenNguoiDung, anhDaiDien, isAdmin = false } = UserRedux;
	if (match.params?.idNguoiDung) {
		const { idNguoiDung } = match.params;
		UserId = idNguoiDung;
	} else {
		UserId = idNguoiDung;
	}

	let errorsObj = { result: "" };
	const [addPermissionModal, setAddPermissionModal] = useState(false);
	const [editPermissionModal, setEditPermissionModal] = useState(false);
	const [disableButtonUser, setDisableButtonUser] = useState(false);
	const [disableButtonSystem, setDisableButtonSystem] = useState(false);
	const [buttonActive, setButtonActive] = useState(Number);
	const [buttonActiveSystem, setButtonActiveSystem] = useState(Number);
	const [idPermissionUser, setIdPermissionUser] = useState(Number);
	const [idPermissionSystem, setIdPermissionSystem] = useState(Number);
	const [arrPermissionUser, setArrPermissionUser] = useState([
		{ id: 1, label: "Người dùng", linkDetail: "#" },
		{ id: 2, label: "Chức năng", linkDetail: "#" },
		{ id: 3, label: "Ngành hàng", linkDetail: "#" },
		{ id: 4, label: "Khu vực", linkDetail: "#" }
	]);
	const [arrPermissionSystem, setArrPermissionSystem] = useState<Array<objectQuyen>>([]);
	const [showLoading, setshowLoading] = useState(false);
	const columnsPermission = useMemo(() => COLUMNS_PERMISSION, []);
	const dataPermission = useMemo(() => MOCK_DATA, []);
	const [dataUser, setDataUser] = useState<any>();
	const [ListUserMenu, setListUserMenu] = useState<Array<objectQuyen>>([]);
	const [errors, setErrors] = useState(errorsObj);
	const listPermission = [
		{ value: '1', label: 'Người dùng' },
		{ value: '2', label: 'Chức năng' },
		{ value: '3', label: 'Ngành hàng' },
		{ value: '4', label: 'Khu vực' },
		{ value: '5', label: 'Điểm kinh doanh (Lô)' },
		{ value: '6', label: 'Bảng giá' },
		{ value: '7', label: 'Cấu hình hệ thống' },
	]

	const options = {
		settings: {
			overlayColor: "#000000",
		},
	};

	const addPermission = () => {
		// Chuyển quyền người dùng sang quyền hệ thống
		let arrItemSelected = [ListUserMenu[idPermissionUser], ...arrPermissionSystem]
		// Danh sách quyền sau khi đã chuyển quyền của người dùng
		let arrItemAfterTransfer = ListUserMenu.filter(item => item.idQuyen !== ListUserMenu[idPermissionUser].idQuyen);
		// Set lại danh sách quyền người dùng sau khi đã chuyển sang quyền hệ thống
		setListUserMenu(arrItemAfterTransfer);
		// Set danh sách quyền hệ thống
		setArrPermissionSystem(arrItemSelected);
		// Set lại trạng thái button khi có quyền người dùng chuyển qua
		setDisableButtonSystem(false);
		// Kiểm tra trong khi chuyển qua quyền hệ thống nếu length < 1 thí sẽ vô hiệu hóa button
		if (arrItemAfterTransfer.length < 1) {
			setDisableButtonUser(true);
		} else {
			setDisableButtonUser(false);
		}
		// log
		// console.log(arrItemSelected);
		// console.log(arrItemAfterTransfer);
	}

	const removePermission = () => {
		// Chuyển quyền hệ thống sang quyền người dùng
		let arrItemSelected = [arrPermissionSystem[idPermissionSystem], ...ListUserMenu];
		// Danh sách quyền sau khi đã chuyển quyền của hệ thống
		let arrItemAfterTransfer = arrPermissionSystem.filter(item => item.tenQuyen !== arrPermissionSystem[idPermissionSystem].tenQuyen);
		// Set lại danh sách quyền hệ thống sau khi đã chuyển sang quyền người dùng
		setArrPermissionSystem(arrItemAfterTransfer);
		// Set danh sách quyền người dùng
		setListUserMenu(arrItemSelected);
		// Set lại trạng thái button khi có quyền hệ thống chuyển qua
		setDisableButtonUser(false);
		// Kiểm tra trong khi chuyển qua quyền người dùng nếu length < 1 thí sẽ vô hiệu hóa button
		if (arrItemAfterTransfer.length < 1) {
			setDisableButtonSystem(true);
		} else {
			setDisableButtonSystem(false);
		}
		// log
		// console.log(arrItemSelected);
		// console.log(arrItemAfterTransfer);
	}

	const addPermissionAll = () => {
		// Chuyển tất cả quyền người dùng sang hệ thống
		let arrItemSelected = [...ListUserMenu, ...arrPermissionSystem];
		// Tạo mảng rỗng sau khi chuyển
		let arrItemAfterTransfer = new Array<any>();
		// Set danh sách quyền người dùng sau khi chuyển sang hệ thống
		setListUserMenu(arrItemAfterTransfer);
		// Set danh sách quyền hệ thống sau khi có quyền người dùng chuyển sang
		setArrPermissionSystem(arrItemSelected);
		// Disabled button sau khi chuyển tát cả quyền
		setDisableButtonUser(true);
		setDisableButtonSystem(false);

	}
	const removePermissionAll = () => {
		// Chuyển tất cả quyền hệ thống sang người dùng
		let arrItemSelected = [...arrPermissionSystem, ...ListUserMenu];
		// Tạo mảng rỗng sau khi chuyển
		let arrItemAfterTransfer = new Array<any>();
		// Set danh sách quyền hệ thống sau khi chuyển sang người dùng
		setArrPermissionSystem(arrItemAfterTransfer);
		// Set danh sách quyền người dùng sau khi có quyền hệ thống chuyển sang
		setListUserMenu(arrItemSelected);
		// Disabled button sau khi chuyển tát cả quyền
		setDisableButtonSystem(true);
		setDisableButtonUser(false);
	}

	const toggleClass = (index: number) => {
		setButtonActive(index);
	};
	const toggleClassSystem = (index: number) => {
		setButtonActiveSystem(index);
	};

	const gInfoUser = async () => {
		if (UserId) {
			const Info = await gDetailNguoiDung(UserId);
			if (Info.success) {
				setDataUser(Info.data);
			}
		} else {
			const Info = await nguoiDungApi.layThongTinCuaToi();
			if (Info.success) {
				setDataUser(Info.data);
			}
		}
	}

	const [selectPermission, setSelectPermission] = useState([])

	const onChange = (values: any) => {
		setSelectPermission(values)
	}

	//lấy danh sách quyền
	const gDanhSachQuyen = async () => {
		if (UserId) {
			const listPer = await chucnangnguoidungApi.getListUserFunction(UserId);
			if (listPer.success) {
				if (listPer.data.length > 0) {
					// setListUserMenu(listPer.data);
					var listResult = listPer.data;
					var quyenHienCo = listResult.filter(item => item.idNguoiDung != null)
					var quyenKhaDung = listResult.filter(item => item.idNguoiDung == null)
					setListUserMenu(quyenHienCo)
					setArrPermissionSystem(quyenKhaDung)
				}

			}
		}
	}
	//xác nhận gán quyền
	const SubmitPermission = async () => {
		if (UserId) {
			setshowLoading(true);
			var idQuyen = ListUserMenu.map(item => item?.idQuyen)
			const result = await chucnangnguoidungApi.confilmPermission(idQuyen, UserId)
			if (result.success) {
				setshowLoading(false)
				swal({
					title: "Phân quyền người dùng",
					text:
						"Phân quyền người dùng thành công",
					icon: "success",
					buttons: {
						cancel: {
							text: "Đóng",
							value: "OK",
							visible: true,
							className: "",
							closeModal: true,
						}
					}
				})
				gDanhSachQuyen()
			} else {
				swal({
					title: "Phân quyền người dùng",
					text:
						"Phân quyền người dùng thất bại",
					icon: "warning",
					buttons: {
						cancel: {
							text: "Đóng",
							value: "OK",
							visible: true,
							className: "",
							closeModal: true,
						}
					}
				})
				setshowLoading(false);
			}
		}

	}

	useEffect(() => {
		setListUserMenu([])
		setArrPermissionSystem([])
		gInfoUser()
		gDanhSachQuyen()
	}, [])

	const handleEditDataPermission = () => {
		setEditPermissionModal(true)
	}
	const handleDelData = () => {
		swal({
			title: "Xác nhận xóa ?",
			text:
				"Khi thực hiện hành động này, bạn không thể hoàn tác",
			icon: "warning",
			buttons: ["Hủy", "Đồng ý"],
			dangerMode: true,
		})
	}


	return (
		<Fragment>
			{/* <PageTitle activeMenu="Thông tin người dùng" motherMenu="Trang chủ" /> */}
			<ModalAddPermission
				show={addPermissionModal}
				onHide={() => setAddPermissionModal(false)}
			/>
			<ModalEditPermission
				show={editPermissionModal}
				onHide={() => setEditPermissionModal(false)}
			/>

			<div className="row">
				<div className="col-lg-12">

					<div className="profile card card-body px-3 pt-3 pb-0">
						<div className="profile-head">
							<div className="photo-content ">
								<div className="cover-photo bg-primary rounded"></div>
							</div>
							<div className="profile-info">
								<div className="profile-photo">
									<img
										src={dataUser?.anhDaiDien ? getFileUrl(dataUser?.anhDaiDien) : avatarPlaceholder}
										className="img-fluid rounded-circle"
										style={{ border: "5px solid #fff", minWidth: 90, minHeight: 90, maxWidth: 90, maxHeight: 90, objectFit: "cover" }}
										alt="profile"
									/>
								</div>
								<div className="profile-details align-items-center">
									<div className="profile-name px-3 pt-2">
										<h4 className="text-primary mb-0">{dataUser?.tenNguoiDung}</h4>
										<p>Quản trị viên</p>
									</div>
									<div className="profile-email px-2 pt-2">
										<h4 className="text-muted mb-0">{dataUser?.email}</h4>
										<p>Email</p>
									</div>
									<Link to="/doi-mat-khau" className="btn btn-primary ms-auto">
										Đổi mật khẩu
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<AccordionCard headerTitle="Thông tin người dùng">
				<>
					<div className="row no-gutters fs-4">
						<div className="col-sm-12 col-md-5 col-lg-2 py-3 fw-bold" style={{ borderBottom: "1px solid rgba(0,0,0,0.2)" }}>Tên đăng nhập</div>
						<div className="col-sm-12 col-md-7 col-lg-10 py-3" style={{ borderBottom: "1px solid rgba(0,0,0,0.2)" }}>{dataUser?.maDangNhap}</div>
					</div>
					<div className="row no-gutters fs-4">
						<div className="col-sm-12 col-md-5 col-lg-2 py-3 fw-bold" style={{ borderBottom: "1px solid rgba(0,0,0,0.2)" }}>Mật khẩu</div>
						<div className="col-sm-12 col-md-7 col-lg-10 py-3" style={{ borderBottom: "1px solid rgba(0,0,0,0.2)" }}>***********</div>
					</div>
					<div className="row no-gutters fs-4">
						<div className="col-sm-12 col-md-5 col-lg-2 py-3 fw-bold" style={{ borderBottom: "1px solid rgba(0,0,0,0.2)" }}>Email</div>
						<div className="col-sm-12 col-md-7 col-lg-10 py-3" style={{ borderBottom: "1px solid rgba(0,0,0,0.2)" }}>{dataUser?.email}</div>
					</div>
					<div className="row no-gutters fs-4">
						<div className="col-sm-12 col-md-5 col-lg-2 py-3 fw-bold" style={{ borderBottom: "1px solid rgba(0,0,0,0.2)" }}>Số điện thoại</div>
						<div className="col-sm-12 col-md-7 col-lg-10 py-3" style={{ borderBottom: "1px solid rgba(0,0,0,0.2)" }}>{formatShowPhoneNumber(dataUser?.soDienThoai)}</div>
					</div>
					<div className="row no-gutters fs-4">
						<div className="col-sm-12 col-md-5 col-lg-2 py-3 fw-bold" style={{ borderBottom: "1px solid rgba(0,0,0,0.2)" }}>Tên người dùng</div>
						<div className="col-sm-12 col-md-7 col-lg-10 py-3 text-capitalize" style={{ borderBottom: "1px solid rgba(0,0,0,0.2)" }}>{dataUser?.tenNguoiDung}</div>
					</div>
					<div className="row no-gutters fs-4">
						<div className="col-sm-12 col-md-5 col-lg-2 py-3 fw-bold" style={{ borderBottom: "1px solid rgba(0,0,0,0.2)" }}>Đơn vị</div>
						<div className="col-sm-12 col-md-7 col-lg-10 py-3" style={{ borderBottom: "1px solid rgba(0,0,0,0.2)" }}>{dataUser?.tenDonVi}</div>
					</div>
					<div className="row no-gutters fs-4">
						<div className="col-sm-12 col-md-5 col-lg-2 py-3 fw-bold" style={{ borderBottom: "1px solid rgba(0,0,0,0.2)" }}>Khóa đến</div>
						<div className="col-sm-12 col-md-7 col-lg-10 py-3" style={{ borderBottom: "1px solid rgba(0,0,0,0.2)" }}>{dataUser?.khoaDen ? moment(dataUser?.khoaDen).format("DD/MM/YYYY") : ""} </div>
					</div>
					<div className="row no-gutters fs-4">
						<div className="col-sm-12 col-md-5 col-lg-2 py-3 fw-bold">Trạng thái</div>
						<div className="col-sm-12 col-md-7 col-lg-10 py-3">{dataUser?.tenTrangThai}</div>
					</div>
				</>
			</AccordionCard>
			<AccordionCard headerTitle="Danh sách quyền truy cập">
				<>
					<div className="row">
						<div className="col">
							<ul >
								<div className="list-permission-header py-2 px-3 text-center fs-5 mb-3 fw-bold">Quyền người dùng hiện có</div>
								<div className="border border-dark list-permission">
									{ListUserMenu.map((arr, index) =>
										<button className={`btn-permission border-0 rounded-0 d-flex align-items-center ${buttonActive === index ? 'active' : ""} bg-transparent btn-block py-2 px-3 text-start `}
											key={index}
											onClick={() => (setIdPermissionUser(index), toggleClass(index))}>{arr?.tenQuyen}
											<Link to={`/chi-tiet-quyen-truy-cap/${arr?.idQuyen}`} className="ms-auto text-primary text-decoration-underline">
												Chi tiết
											</Link>
										</button>
									)}
								</div>
							</ul>
						</div>
						<div className="col d-flex align-items-center" style={{ maxWidth: 350 }}>
							<div className="w-100">
								{errors.result && (
									<div className="text-danger fs-12 text-center">
										{errors.result}
									</div>
								)}
								<button className="btn btn-block btn-action-permission text-center px-1 mb-2" onClick={addPermission} disabled={isAdmin ? disableButtonUser : true}>
									Gỡ bỏ quyền <i className="far fa-angle-right ms-2"></i>
								</button>
								<button className="btn btn-block btn-action-permission text-center px-1 mb-2" onClick={addPermissionAll} disabled={isAdmin ? disableButtonUser : true}>
									Gỡ bỏ tất cả quyền <i className="far fa-angle-double-right ms-2"></i>
								</button>
								<div className="dropdown-divider my-4"></div>
								<button className="btn btn-block btn-action-permission text-center px-1 mb-2" onClick={removePermission} disabled={isAdmin ? disableButtonSystem : true}>
									<i className="far fa-angle-left me-2"></i> Gán quyền cho người dùng
								</button>
								<button className="btn btn-block btn-action-permission text-center px-1" onClick={removePermissionAll} disabled={isAdmin ? disableButtonSystem : true}>
									<i className="far fa-angle-double-left me-2"></i> Gán tất cả quyền cho ngươi dùng
								</button>
							</div>
						</div>


						<div className="col">
							<ul>
								<div className="list-permission-header py-2 px-3 text-center fs-5 mb-3 fw-bold">Quyền khả dụng</div>
								<div className="border border-dark list-permission">
									{isAdmin && arrPermissionSystem.map((arr, index) =>
										<button className={`btn-permission border-0 rounded-0 d-flex align-items-center ${buttonActiveSystem === index ? 'active' : ""} bg-transparent btn-block py-2 px-3 text-start `} key={index} onClick={() => (setIdPermissionSystem(index), toggleClassSystem(index))}>
											{arr?.tenQuyen} <Link to={`/chi-tiet-quyen-truy-cap/${arr?.idQuyen}`} className="ms-auto text-primary text-decoration-underline">Chi tiết</Link>
										</button>
									)}
								</div>
							</ul>
						</div>
					</div>

					<div className="text-end border-top mt-4 pt-3">
						<ButtonLoading className="btn btn-primary px-5" type="submit" loading={showLoading} disabled={isAdmin ? false : true}
							onClick={SubmitPermission}>Xác nhận</ButtonLoading>
					</div>
				</>
			</AccordionCard>

		</Fragment>
	);
};

export default AppProfile;
