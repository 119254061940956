/// Menu
import React, { useEffect, useState } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link

/// Image
import 'metismenujs/dist/metismenujs.css';
import { Accordion } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectorPermission } from "../../../store/slices/userPermission";
import { PermissionTree } from "../../../until";
import MenuItem from "../../components/MenuItem";
import SubMenuItem from "../../components/SubMenuItem";
import "./style.css";

const SideBar: React.FC = () => {

  const [permissionTree, setPermissionTree] = useState<PermissionTree>({});
  const dataMenu = useSelector(selectorPermission);
  const { permission } = dataMenu;
  let path: any = window.location.pathname;
  path = path.substring(1);

  useEffect(() => {
    const perTree = JSON.parse(localStorage.getItem("permissionTree") ?? "");
    setPermissionTree(perTree);
  }, [permission])

  useEffect(() => {

    var btn: any = document.querySelector(".nav-control");
    var aaa: any = document.querySelector("#main-wrapper");

    function toggleFunc(this: any, ev: any) {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);

    //sidebar icon Heart blast
    var handleheartBlast = document.querySelector('.heart');
    function heartBlast() {
      return handleheartBlast?.classList.toggle("heart-blast");
    }
    handleheartBlast?.addEventListener('click', heartBlast);

  }, []);

  const handleClickMenuItem = () => {
    let elBody: any = document.querySelector(".sidebar-menu-item button[aria-expanded='true']");
    elBody.click();
  }

  const renderMenu = (permissionTree: PermissionTree, status: boolean = false) => {
    // convert Permission to array and sort by stt
    const menus = Object.values(permissionTree).sort((a, b) => {
      if (a.stt && b.stt) {
        return a.stt - b.stt;
      }
      return 0;
    });
    return menus.map((menu) => {
      var child: any = undefined;
      if (menu.child) {
        child = renderMenu(menu.child, true);
      }
      if (child) {
        return (
          <>
            <Accordion.Item className="sidebar-menu-item position-relative mb-0" eventKey={`${menu.tenMenu}`} key={menu.tenMenu}>
              <Accordion.Header><i className={`${menu.hinhAnh ?? undefined}`}></i><span>{menu.tenMenu}</span></Accordion.Header>
              <Accordion.Body className="sidebar-menu-item-child py-0">
                {child}
              </Accordion.Body>
            </Accordion.Item>
          </>
        )
      } else {
        if (status) {
          return (
            <>
              <SubMenuItem
                key={menu.url}
                className={`${menu?.pathobj && menu?.pathobj.includes(path) ? "mm-active" : ""}`}
                to={menu?.url ?? ''}
                title={menu.tenMenu} />
            </>
          )
        } else {
          return (
            <>
              <MenuItem key={menu.tenMenu}
                className={`sidebar-menu-item position-relative ${menu?.pathobj && menu?.pathobj.includes(path) ? "mm-active" : ""}`}
                onClick={handleClickMenuItem}
                icon={menu?.hinhAnh ?? undefined}
                to={menu?.url ?? ''}
                title={menu.tenMenu} />
            </>
          )
        }
      }
    })
  }

  return (
    <div
      className={`dlabnav`}
    >
      <PerfectScrollbar className="dlabnav-scroll">
        <Accordion>
          {renderMenu(permissionTree)}
        </Accordion>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
