import { paserParams } from "../until";
import axiosClient, { ResponseSuccess, ResponseError } from "./axiosClient";
import { ResponseStatus } from "./matBangLoApi";

interface menu {
    idMenu?: string,
    tenMenu?: string
}
//quyền
export interface Permission {
    idQuyen?: string;
    tenQuyen: string,
    tenMenu?: string;
    menu?: menu[];
    moTa: string,
    taoBoi?: string;
    taoLuc?: string;
    capNhatBoi?: string;
    capNhatLuc?: string;
    hoatDong?: boolean;
}
export interface PermissionList {
    data: Permission[];
    totalRow?: number
}

export interface AddPermission {
    data: {
        idQuyen?: string;
    }

}
export interface UpdatePermission {
    data: Permission
}

//danh sách
export const getListPermission =
    async (PageNumber: number, PageSize: number = 25, TenQuyen: string = "", MoTa: string = "", Startday: string = "", endday: string = "", HoatDong: boolean | undefined = undefined, IdQuyen: string = "") => {

        const params = {
            PageNumber,
            PageSize,
            search: {
                IdQuyen,
                MoTa,
                HoatDong,
                TenQuyen,

                capNhatLuc: {
                    start: Startday,
                    end: endday
                }
            }
        }
        const path = "quyen/items"
        const result: ResponseSuccess<PermissionList> = await axiosClient.get(path, {
            params: paserParams(params, undefined)
        });
        return result;
    }

//thông tin 
export const getInfoPermission = async (idQuyen: string) => {
    const path = "quyen/" + idQuyen;
    const result: ResponseSuccess<Permission> = await axiosClient.get(path, {
    });
    return result;
}

//thêm //chưa có
export const createPermission =
    async (TenQuyen: string, MoTa: string, HoatDong: boolean, idMenu: Array<any> = []
    ) => {
        const path = "quyen";
        const params = {
            TenQuyen,
            MoTa,
            IdMenu: idMenu,
            HoatDong,

        }
        const result: ResponseSuccess<AddPermission> = await axiosClient.post(path,
            paserParams(params, "")
        );
        return result;
    }

//sửa
//chưa có
export const capNhatQuyen =
    async (
        idQuyen: string, tenQuyen: string, moTa?: string, hoatDong?: boolean, idMenu: Array<any> = []
    ) => {
        const path = "quyen/";
        let params = {
            IdQuyen: idQuyen,
            TenQuyen: tenQuyen,
            MoTa: moTa,
            IdMenu: idMenu,
            HoatDong: hoatDong,
        }
        const result: ResponseSuccess<UpdatePermission> = await axiosClient.put(path,
            paserParams(params, "")
        );
        return result;
    }

//xóa
export const removePermission = async (idQuyen: string) => {
    const path = "quyen/" + idQuyen;
    const result: ResponseStatus = await axiosClient.delete(path, {
    });
    return result;
}

