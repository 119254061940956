import { paserParams } from "../until";
import axiosClient, { ResponseSuccess, ResponseError } from "./axiosClient";

export interface UserInfo {
    data: {
        idNguoiDung?: string;
        email?: string;
        xacThucEmail?: string;
        soDienThoai?: string;
        anhDaiDien?: string;
        tenNguoiDung?: string;
        khoaDen?: string;
        lanTruyCapLoi?: string;
        idTrangThai?: string;
        idDonVi?: string;
        taoBoi?: string;
        taoLuc?: string;
        capNhatBoi?: string;
        capNhatLuc?: string;
    }
    accessToken: string | null
    expiration: string | null
}
export interface ResponseStatus {
    success?: boolean;
    message?: string;

}
// export const gNguoiDung = async () => {
//     const path = "nguoi-dung";
//     const result: ResponseSuccess<UserInfo> = await axiosClient.get(path, {});

//     return result;
// }
export const gDetailNguoiDung = async (idNguoiDung: string) => {
    const path = "nguoi-dung/" + idNguoiDung;
    const result: ResponseSuccess<UserInfo> = await axiosClient.get(path, {});

    return result;
}
//đổi mật khẩu
export const changePassword = async (MatKhauCu: string, MatKhauMoi: string) => {
    const path = "nguoi-dung/doi-mat-khau";
    const params = {
        MatKhauCu, MatKhauMoi
    }
    const result: ResponseStatus = await axiosClient.post(path, paserParams(params, ""));
    return result;
}