import { Link } from 'react-router-dom';
import { Cell } from 'react-table';
export const COLUMNS_PERMISSION = [
    {
        Header: 'STT',
        Footer: 'STT',
        accessor: 'stt',
        Filter: "",
        Cell: (cell: Cell) => <div className="text-nowrap">{cell.value}</div>
    },
    {
        Header: 'Mã quyền',
        Footer: 'Mã quyền',
        accessor: 'permission_code',
        Filter: "",
        Cell: (cell: Cell) => <div className="text-nowrap"><Link to="/chi-tiet-quyen-truy-cap" className="text-primary text-decoration-underline">{cell.value}</Link></div>
    },
    {
        Header: 'Tên quyền',
        Footer: 'Tên quyền',
        accessor: 'permission_name',
        Filter: "",
    },
    {
        Header: 'Mô tả',
        Footer: 'Mô tả',
        accessor: 'note',
        Filter: "",
    },
    {
        Header: 'Thời gian tạo',
        Footer: 'Thời gian tạo',
        accessor: 'date_create',
        Filter: "",
        Cell: (cell: Cell) => {
            return <div className="text-center">{cell.value}</div>
        }
    },
    {
        Header: 'Thời gian cập nhật',
        Footer: 'Thời gian cập nhật',
        accessor: 'date_update',
        Filter: "",
        Cell: (cell: Cell) => {
            return <div className="text-center">{cell.value}</div>
        }
    },


]

export const GROUPED_COLUMNS = [
    {
        Header: 'Id',
        Footer: 'Id',
        accessor: 'id'
    },
    {
        Header: 'Name',
        Footer: 'Name',
        columns: [
            {
                Header: 'First Name',
                Footer: 'First Name',
                accessor: 'first_name'
            },
            {
                Header: 'Last Name',
                Footer: 'Last Name',
                accessor: 'last_name'
            },
        ]
    },
    {
        Header: 'Info',
        Footer: 'Info',
        columns: [
            {
                Header: 'Date of  Birth',
                Footer: 'Date of  Birth',
                accessor: 'date_of_birth'
            },
            {
                Header: 'Country',
                Footer: 'Country',
                accessor: 'country',
            },
            {
                Header: 'Phone',
                Footer: 'Phone',
                accessor: 'phone'
            },
        ]
    },
]