
import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import { selectorAlert, shiftAlert, TAlert } from '../../store/slices/alertSlice';

// const ICON_ALERT = {
//     success: checkmarkCircle,
//     warning: alertCircle,
//     danger: closeCircle
// }

const AlertGlobal = () => {
    const dispatch = useDispatch();
    const { alerts } = useSelector(selectorAlert);
    const [alert, setAlert] = useState<TAlert>({
        title: "Thông Báo",
        titleText: "Thành công",
        buttonText: "Xác nhận",
        buttonValue: "OK",
        icon: "success",
        timer: 3000
    });
    const [isShow, setIsShow] = useState(false);


    useEffect(() => {
        if (!isShow) {
            showNextAlert();
        }
    }, [alerts])

    const showNextAlert = async () => {

        if (alerts && alerts.length > 0) {
            setAlert(alerts[0]);
            // setIsShow(true);
            if (alerts[0]?.timer == 0) {
                swal({
                    title: alerts[0]?.title,
                    text: alerts[0]?.titleText,
                    icon: alerts[0].icon,
                    buttons: {
                        cancel: {
                            text: alerts[0]?.buttonText,
                            value: alerts[0]?.buttonValue,
                            visible: true,
                            className: "",
                            closeModal: true,
                        }
                    }
                })
            }
            else {
                swal({
                    title: alerts[0]?.title,
                    text: alerts[0]?.titleText,
                    icon: alerts[0].icon,
                    timer: alerts[0]?.timer,
                    buttons: {
                        cancel: {
                            text: alerts[0]?.buttonText,
                            value: alerts[0]?.buttonValue,
                            visible: true,
                            className: "",
                            closeModal: true,
                        }
                    }
                })
            }

            dispatch(shiftAlert());
        }
    }

    const onDidDismiss = () => {
        setIsShow(false);
        showNextAlert();
    }

    return <>
        <div hidden>{alert?.titleText}</div>

    </>
}

export default memo(AlertGlobal);