import { Fragment, lazy, Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';
import "./css/style.css";
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import React, { useContext } from "react";
import { ThemeContext } from "./context/ThemeContext";
import AlertGlobal from './jsx/components/AlertGlobal';
import AppProfile from "./jsx/components/AppsMenu/AppProfile/AppProfile";
import Home from "./jsx/components/Dashboard/Home";
import useAlert from './jsx/hooks/useAlert';
import Footer from "./jsx/layouts/Footer";
import Nav from "./jsx/layouts/nav";
import TopBar from './jsx/layouts/nav/TopBar';
import { selectorPermission } from './store/slices/userPermission';
const Login = lazy(() => {
    return new Promise((resolve) => {
        setTimeout(resolve, 500)
    })
        .then(() => import('./jsx/pages/Login'));
});
const SignUp = lazy(() => import('./jsx/pages/Registration'));

/// pages

// const Home = lazy(() => import('./jsx/components/Dashboard/Home'));
// Menu người dùng
const MenuUser = lazy(() => import('./jsx/pages/MenuUser'));
// Menu chức năng
const MenuFunction = lazy(() => import('./jsx/pages/MenuFunction'));
// Menu ngành nghề
const MenuIndustry = lazy(() => import('./jsx/pages/MenuIndustry'));
// Menu khu vực
const KhuVuc = lazy(() => import('./jsx/pages/KhuVuc'));
const ListSubArea = lazy(() => import('./jsx/pages/ListSubArea'));
// Menu loại điểm kinh doanh (Lô)
const MatBang = lazy(() => import('./jsx/pages/MatBang'));
const DetailAreaType = lazy(() => import("./jsx/pages/DetailAreaType"));
// Menu khu vực
const MenuLand = lazy(() => import('./jsx/pages/MenuLand'));
// Menu bảng giá
const MenuPriceList = lazy(() => import('./jsx/pages/MenuPriceList'));
// Menu quyền truy cập
const MenuPermission = lazy(() => import('./jsx/pages/MenuPermission'));
// Menu cấu hình hệ thống
const MenuSetting = lazy(() => import('./jsx/pages/MenuSetting'));
const DetailPermission = lazy(() => import('./jsx/pages/DetailPermission'));
// Menu quản lý thu
const CustomerRegister = lazy(() => import("./jsx/pages/CustomerRegister"));

const HoKinhDoanh = lazy(() => import("./jsx/pages/HoKinhDoanh"));
const HoSoKhachHang = lazy(() => import("./jsx/pages/HoSoKhachHang"));
// Các trang chức năng
const ChangePassword = lazy(() => import('./jsx/pages/ChangePassword'));
// Menu danh mục
const BanQuanLy = lazy(() => import("./jsx/pages/BanQuanLy"));
const GioiTinh = lazy(() => import("./jsx/pages/GioiTinh"));
const HienTrang = lazy(() => import("./jsx/pages/HienTrang"));
const HinhThucThanhToan = lazy(() => import("./jsx/pages/HinhThucThanhToan"));
const PhanLoaiHoaDon = lazy(() => import("./jsx/pages/PhanLoaiHoaDon"));
const SuDungDien = lazy(() => import("./jsx/pages/SuDungDien"));
const SuDungNuoc = lazy(() => import("./jsx/pages/SuDungNuoc"));
const TrangThaiDangKy = lazy(() => import("./jsx/pages/TrangThaiDangKy"));
const TrangThaiHoaDon = lazy(() => import("./jsx/pages/TrangThaiHoaDon"));
const TrangThaiSuDung = lazy(() => import("./jsx/pages/TrangThaiSuDung"));
const KieuHopDong = lazy(() => import("./jsx/pages/kieuHopDong"));
const LoaiDonVi = lazy(() => import("./jsx/pages/LoaiDonVi"));
const LoaiGiayTo = lazy(() => import("./jsx/pages/LoaiGiayTo"));
const DanhMucCHo = lazy(() => import("./jsx/pages/DanhMucCho"));
const LoaiHoaDon = lazy(() => import("./jsx/pages/LoaiHoaDon"));
const KhoanMucPhi = lazy(() => import("./jsx/pages/KhoanMucPhi"));

const ChiTietBanQuanLy = lazy(() => import("./jsx/pages/ChiTietBanQuanLy"));
const ChiTietCho = lazy(() => import("./jsx/pages/ChiTietCho"));
const ChiTietKhoanMucPhi = lazy(() => import("./jsx/pages/ChiTietKhoanMucPhi"));
const ChiTietPhanLoaiHoaDon = lazy(() => import("./jsx/pages/ChiTietPhanLoaiHoaDon"));
const ChiTietMatBang = lazy(() => import("./jsx/pages/ChiTietMatBang"));
const NhomVe = lazy(() => import("./jsx/pages/NhomVe"));
const LoaiVe = lazy(() => import("./jsx/pages/LoaiVe"));

// Menu Quản lý thu
const PhatSinhTrongThang = lazy(() => import("./jsx/pages/PhatSinhTrongThang"));
const PhatSinhHangRong = lazy(() => import("./jsx/pages/PhatSinhHangRong"));
const ThuPhiDichVu = lazy(() => import("./jsx/pages/ThuPhiDichVu"));
const ThuNoDong = lazy(() => import("./jsx/pages/ThuNoDong"));
const DieuChinhKeHoachThu = lazy(() => import("./jsx/pages/DieuChinhKeHoachThu"));
const LichSuDieuChinhKeHoachThu = lazy(() => import("./jsx/pages/LichSuDieuChinhKeHoachThu"));
const ThuTraTruoc = lazy(() => import("./jsx/pages/ThuTraTruoc"));


const ThongTinKhachHang = lazy(() => import("./jsx/pages/ThongTinKhachHang"));

// const ThuPhiHangRong = lazy(() => import("./jsx/pages/ThuPhiHangRong"));
const SoLieuSoBoTongHop = lazy(() => import("./jsx/pages/SoLieuSoBoTongHop"));
const SoLieuSoBoChiTiet = lazy(() => import("./jsx/pages/SoLieuSoBoChiTiet"));
const XuatSoBo = lazy(() => import("./jsx/pages/XuatSoBo"));
const ThuTheoKeHoach = lazy(() => import("./jsx/pages/ThuTheoKeHoach"));
// Menu Quản lý đăng ký
const PheDuyetDangKyMoi = lazy(() => import("./jsx/pages/PheDuyetDangKyMoi"));
const PheDuyetNghiHan = lazy(() => import("./jsx/pages/PheDuyetNghiHan"));
const ChiTietPheDuyetKhachHang = lazy(() => import("./jsx/pages/ChiTietPheDuyetKhachHang"));
// Menu Báo cáo
const BaoCaoDanhSachHR = lazy(() => import("./jsx/pages/BaoCaoDanhSachHR"));
const BaoCaoDanhSachThu = lazy(() => import("./jsx/pages/BaoCaoDanhSachThu"));
const BaoCaoDanhSachThu2 = lazy(() => import("./jsx/pages/BaoCaoDSThu2"));
const BaoCaoCongNo = lazy(() => import("./jsx/pages/BaoCaoCongNo"));
const MauBaoCao = lazy(() => import("./jsx/pages/MauBaoCao"));
const BaoCaoHDKDCacCho = lazy(() => import("./jsx/pages/BaoCaoHDKDCacCho"));
const BaoCaoSoBoTongHop = lazy(() => import("./jsx/pages/BaoCaoSoBoTongHop"));
const BaoCaoSoBoChiTiet = lazy(() => import("./jsx/pages/BaoCaoSoBoChiTiet"));
const BaoCaoDSThu = lazy(() => import("./jsx/pages/BaoCaoDSThu"));
const BaoCaoHDKD = lazy(() => import("./jsx/pages/BaoCaoHDKD"));
const BaoCaoSoBoChiTietKLKD = lazy(() => import("./jsx/pages/BaoCaoSoBoChiTietKLKD"));
const BaoCaoThongKeVe = lazy(() => import("./jsx/pages/BaoCaoThongKeVe"));
const BaoCaoVeLe = lazy(() => import("./jsx/pages/BaoCaoVeLe"));
// Hóa đơn điện tử
const HoaDonDienTu = lazy(() => import("./jsx/pages/HoaDonDienTu"));
const DanhSachHoaDon = lazy(() => import("./jsx/pages/DanhSachHoaDon"));
const ChiTietDieuChinhKeHoach = lazy(() => import("./jsx/pages/ChiTietDieuChinhKeHoach"));
// Vé điện tử
const VeDienTu = lazy(() => import("./jsx/pages/VeDienTu"));
const HoaDonKhac = lazy(() => import("./jsx/pages/HoaDonKhac"));
const VeGuiXeMay = lazy(() => import("./jsx/pages/VeGuiXeMay"));



const App: React.FC = () => {
    const pushAlert = useAlert();
    const menuToggle = useContext(ThemeContext);
    const location = useLocation();
    const dataMenu = useSelector(selectorPermission);
    const { permission } = dataMenu;
    let path: any = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];

    let pagePath = path.split("-").includes("page");

    useEffect(() => {
        var url = (window.location.pathname).split("/")[1];

        if (url.search("dang-ky-khach-hang-by-id") > -1 || url.search("thu-phi-hang-rong-by-id") > -1) {
            return
        }
        if (window.location.pathname != "/" && window.location.pathname != "/login" && window.location.pathname != "/trang-ca-nhan" && window.location.pathname != "/doi-mat-khau" && window.location.pathname.search("chi-tiet") < 0) {
            if (!permission.includes("/" + url)) {
                alert('Bạn không có quyền truy cập vào chức năng này')
                window.history.back();
            }
        }

    }, [window.location.pathname])

    let routes = (
        <Fragment>
            <TopBar />
            <div
                id={`${!pagePath ? "main-wrapper" : ""}`}
                className={`${!pagePath ? "show" : "mh100vh"}  ${menuToggle ? "" : "menu-toggle"
                    }`}
            >
                {!pagePath && <Nav />}

                <div className={`${!pagePath ? "content-body" : ""}`}>
                    <div
                        className={`${!pagePath ? "container-fluid pb-5" : ""}`}
                    // style={{ minHeight: window.screen.height - 60 }}
                    >
                        <Route path='/trang-chu' component={Home} />
                        <Route path='/nguoi-dung' component={MenuUser} />
                        {/* <Route path='/chi-tiet-nguoi-dung' component={AppProfile} /> */}
                        <Route path='/chi-tiet-nguoi-dung/:idNguoiDung' component={AppProfile} />
                        <Route path='/chuc-nang' component={MenuFunction} />
                        <Route path='/nganh-hang' component={MenuIndustry} />
                        <Route path='/khu-vuc' component={KhuVuc} />
                        <Route path='/loai-mat-bang' component={MenuLand} />
                        <Route path='/mat-bang-lo' component={MatBang} />
                        <Route path='/bang-gia' component={MenuPriceList} />
                        <Route path='/quyen-truy-cap' component={MenuPermission} />
                        <Route path='/cau-hinh-he-thong' component={MenuSetting} />
                        <Route path='/trang-ca-nhan' component={AppProfile} />
                        <Route path='/chi-tiet-khu-vuc/:idKhuVuc' component={ListSubArea} />
                        <Route path='/chi-tiet-quyen-truy-cap/:idQuyen' component={DetailPermission} />
                        <Route path='/doi-mat-khau' component={ChangePassword} />
                        <Route path='/chi-tiet-lo/:idMatBang' component={DetailAreaType} />
                        <Route path='/ban-quan-ly' component={BanQuanLy} />
                        <Route path='/gioi-tinh' component={GioiTinh} />
                        <Route path='/hien-trang' component={HienTrang} />
                        <Route path='/hinh-thuc-thanh-toan' component={HinhThucThanhToan} />
                        <Route path='/phan-loai-hoa-don' component={PhanLoaiHoaDon} />
                        <Route path='/loai-hoa-don' component={LoaiHoaDon} />
                        <Route path='/su-dung-dien' component={SuDungDien} />
                        <Route path='/su-dung-nuoc' component={SuDungNuoc} />
                        <Route path='/trang-thai-dang-ky' component={TrangThaiDangKy} />
                        <Route path='/trang-thai-hoa-don' component={TrangThaiHoaDon} />
                        <Route path='/trang-thai-su-dung' component={TrangThaiSuDung} />
                        <Route path='/kieu-hop-dong' component={KieuHopDong} />
                        <Route path='/loai-don-vi' component={LoaiDonVi} />
                        <Route path='/loai-giay-to' component={LoaiGiayTo} />
                        <Route path='/danh-muc-cho' component={DanhMucCHo} />
                        <Route path='/khoan-muc-phi' component={KhoanMucPhi} />
                        <Route path='/nhom-ve' component={NhomVe} />
                        <Route path='/loai-ve' component={LoaiVe} />
                        {/* Menu Quản lý HKD */}
                        <Route path='/dang-ky-khach-hang' component={CustomerRegister} />
                        <Route path='/ho-kinh-doanh' component={HoKinhDoanh} />
                        <Route path='/ho-so-khach-hang' component={HoSoKhachHang} />

                        <Route path='/chi-tiet-bang-quan-ly/:idBangQuanLy' component={ChiTietBanQuanLy} />
                        <Route path='/chi-tiet-cho/:idCho' component={ChiTietCho} />
                        <Route path='/chi-tiet-Khoan-muc-phi/:idKhoanMucPhi' component={ChiTietKhoanMucPhi} />
                        <Route path='/chi-tiet-phan-loai-hoa-don/:idPhanLoaiHoaDon' component={ChiTietPhanLoaiHoaDon} />
                        <Route path='/chi-tiet-mat-bang/:idMatBang' component={ChiTietMatBang} />

                        {/* Menu Quản lý thu */}
                        <Route path='/phat-sinh-trong-thang' component={PhatSinhTrongThang} />
                        <Route path='/thu-phi-hang-rong' component={PhatSinhHangRong} />
                        <Route path='/thu-phi-dich-vu' component={ThuPhiDichVu} />
                        <Route path='/thu-no-dong' component={ThuNoDong} />
                        <Route path='/danh-sach-dieu-chinh' component={DieuChinhKeHoachThu} />
                        <Route path='/lich-su-dieu-chinh-ke-hoach-thu' component={LichSuDieuChinhKeHoachThu} />
                        <Route path='/chi-tiet-dieu-chinh-ke-hoach-thu/:IdDieuChinh/:IdKhoanMucPhi/:IdCho' component={ChiTietDieuChinhKeHoach} />
                        <Route path='/thong-tin-chi-tiet-khach-hang/:idKhachHang' component={ThongTinKhachHang} />
                        <Route path='/thu-tra-truoc' component={ThuTraTruoc} />

                        {/* <Route path='/thu-phi-hang-rong1' component={ThuPhiHangRong} /> */}
                        <Route path='/so-lieu-so-bo-tong-hop' component={SoLieuSoBoTongHop} />
                        <Route path='/so-lieu-so-bo-chi-tiet' component={SoLieuSoBoChiTiet} />
                        <Route path='/xuat-so-bo' component={XuatSoBo} />
                        <Route path='/thu-theo-ke-hoach' component={ThuTheoKeHoach} />

                        <Route path='/dang-ky-khach-hang-by-id/:id' component={CustomerRegister} />
                        <Route path='/thu-phi-hang-rong-by-id/:idKhachHang/:idSuDungMatBang' component={PhatSinhHangRong} />
                        {/* Menu Quản lý đăng ký */}
                        <Route path='/phe-duyet-dang-ky-moi' component={PheDuyetDangKyMoi} />
                        <Route path='/phe-duyet-nghi-han' component={PheDuyetNghiHan} />
                        <Route path='/chi-tiet-phe-duyet/:idKhachHangTam' component={ChiTietPheDuyetKhachHang} />
                        {/* Menu Báo cáo */}
                        <Route path='/bao-cao-thu-hang-rong' component={BaoCaoDanhSachHR} />
                        <Route path='/bao-cao-danh-sach-thu' component={BaoCaoDanhSachThu} />
                        <Route path='/bao-cao-danh-sach-thu-2' component={BaoCaoDanhSachThu2} />
                        <Route path='/bao-cao-cong-no' component={BaoCaoCongNo} />
                        <Route path='/mau-bao-cao' component={MauBaoCao} />
                        <Route path='/bao-cao-hdkd-cac-cho' component={BaoCaoHDKDCacCho} />
                        <Route path='/bao-cao-so-bo-tong-hop' component={BaoCaoSoBoTongHop} />
                        <Route path='/bao-cao-so-bo-chi-tiet' component={BaoCaoSoBoChiTiet} />
                        <Route path='/bao-cao-ds-thu' component={BaoCaoDSThu} />
                        <Route path='/bao-cao-hdkd' component={BaoCaoHDKD} />
                        <Route path='/bao-cao-bien-dong-hkd' component={BaoCaoSoBoChiTietKLKD} />
                        <Route path='/bao-cao-thong-ke-ve' component={BaoCaoThongKeVe} />
                        <Route path='/bao-cao-ve-le' component={BaoCaoVeLe} />
                        {/* Hóa đơn điện tử */}
                        <Route path='/hoa-don-dien-tu' component={HoaDonDienTu} />
                        <Route path='/danh-sach-hoa-don' component={DanhSachHoaDon} />
                        {/* Vé điện tử */}
                        <Route path='/ve-dien-tu' component={VeDienTu} />
                        <Route path='/ve-gui-xe-may' component={VeGuiXeMay} />
                        <Route path='/hoa-don-khac' component={HoaDonKhac} />

                    </div>
                </div>
                {!pagePath && <Footer />}
            </div>

        </Fragment>
    );

    return (
        // <div className="vh-100">
        <div>
            <AlertGlobal />
            <Suspense fallback={
                <div id="preloader">
                    <div className="sk-three-bounce">
                        <div className="sk-child sk-bounce1"></div>
                        <div className="sk-child sk-bounce2"></div>
                        <div className="sk-child sk-bounce3"></div>
                    </div>
                </div>
            }
            >
                <Switch>
                    {/* {routesNoHeaderFooter} */}
                    <Route path='/login' component={Login} />
                    <Route exact path='/' component={Login} />
                    <Route path='/page-register' component={SignUp} />
                    {routes}
                </Switch>


            </Suspense>
        </div>
    );
};

export default App;

